.prefooter-blocks-large {
  margin: 0 auto;
}
.prefooter-blocks-large .row {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.prefooter-blocks-large .block-wrap {
  margin-bottom: 2rem;
}
.prefooter-blocks-large .block {
  padding: 0 15px;
  margin-bottom: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  height: 100%;
}
.prefooter-blocks-large .block p {
  max-width: 100%;
}
.prefooter-blocks-large svg {
  margin-bottom: 2rem;
}
.prefooter-blocks-large strong {
  margin-bottom: 1rem;
  font-size: 20px;
}
.prefooter-blocks-large p {
  font-size: 16px;
  margin-bottom: 0;
}